export enum ErrorType {
  MISSING_OR_INVALID_INPUT_DATA = 100,
  MISSING_REQUEST_TYPE = 101,
  MISSING_CUSTOMER_ID = 102,
  MISSING_TOKEN = 103,
  MISSING_OR_INVALID_REQUEST_DATA = 110,
  UNPARSABLE_INPUT = 120,
  INVALID_CUSTOMER_ID = 202,
  INVALID_INVITE = 203,
  EMAIL_USED = 211,
  IBAN_USED = 212,
  ALREADY_REGISTERED = 213,
  PHONE_USED = 214,
  IBAN_COUNTRY_NOT_SUPPORTED = 220,
  IBAN_NOT_ALLOWED = 221,
  INVALID_REFERRAL_URL = 230,
  REFERRAL_URL_USED = 231,
  NO_PARTNER_LOGO = 240,
  FINTEC_UNSUPPORTED = 250,
  AUTHENTICATION_FAILED = 300,
  NO_USER_FOR_EMAIL = 301,
  AUTHENTICATION_ERROR = 302,
  INVALID_STATUS = 400,
  TOPUP_NOT_ALLOWED = 401,
  OVERDUE_EDIT_NOT_ALLOWED = 402,
  VERIFICATION_FAILED = 500,
  NOT_ALLOWED = 501,
  VERIFICATION_TIMEOUT = 502,
  VERIFICATION_TOO_MANY_TRIES = 503,
  LIMIT_EXCEEDED = 600,
  INVALID_CURRENCY = 601,
  INVALID_PAYMENT_METHOD = 602,
  INVALID_AMOUNT = 603,
  INVALID_INTERSTFREE_UNTIL = 604,
  INVALID_TARGETACCOUNT = 605,
  NO_ACTIVE_CAMPAIGN_FOR_USER = 700,
  COMMUNICATION_ERROR = 800,
  INTERNAL_SERVER_ERROR = 900
}
